import React from 'react';
import { Component } from 'react';
import logo from './img/logo.png';
import './css/style.min.css'
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
        swiper: ''
    }
}
  componentDidMount(){
    console.log(this.props)
  }
  render() {
    return (
        <div className="footer">
        <div className="wauto">
            <div className="top">
                <img src={logo} alt="" className="logo" />
                <div className="right">
                    <div></div>
                    <div className="list">
                        <h5 className="h5">BTX Capital</h5>
                        <a className="a" href="">首页</a>
                        <a className="a" href="">产品与服务</a>
                        <a className="a" href="">相关报道</a>
                        <a className="a" href="">关于我们</a>
                        <a className="a" href="">市场策略</a>
                        <a className="a" href="">联系我们</a>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div>© 2020 BTX Capital  版权所有</div>
                <div>商业计划书请发送至Email： xm@btxcapital.com</div>
            </div>
        </div>
    </div>
    )
  }
}
export default Footer