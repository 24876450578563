
const CHAT={
    IsPC() {  
        var userAgentInfo = navigator.userAgent;
        var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");  
        var flag = true;  
        for (var v = 0; v < Agents.length; v++) {  
            if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }  
        } 
        var isPhone
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
            isPhone = true
        } else if (/(Android)/i.test(navigator.userAgent)) {
            //alert(navigator.userAgent); 
            isPhone = true
        } else {
            isPhone = false
        };
        return isPhone; 
     
    }
} 

export default CHAT; // 将它导出