import React from 'react'
import Header from '../header';
import { Component } from "react";
import banner from '../img/newsbanner.jpg'
import newsTest from '../img/newsTest.jpg'
import Footer from '../footer';
import '../css/style.min.css'
class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [1, 2, 3, 4, 5, 6, 7, 8, 9]
        }
    }
    componentDidMount() {

    }
    godatails(s,params) {
        this.props.history.push({pathname:`/detalis/id=${s}`,state:{data:s}})
        console.log(s)
    }
    componentWillUnmount() {

    }
    render() {
        return (
            <div>
                <Header data={'relative'} />
                <img className="probanner" src={banner} alt="" />
                <div className="newsCare wauto">
                    <div className="tbox">
                        <h2>BTX动态  / 媒体相关报道</h2>
                        <i></i>
                    </div>
                    <div className="list">
                        {this.state.list.map((v, i) => (
                            <div className="item" key={i}>
                                <img className="img" src={newsTest} alt="" />
                                <div className="right">
                                    <div className="hand">中国银行通过路孚特Eikon平台发布外汇价格预测应用 </div>
                                    <p className="time">2020年4月21日</p>
                                    <p className="text">近日，创业36计和胡润百富、胡润研究院，经过长达一年的数据与实地调研后...</p>
                                    <div className="btn" onClick={this.godatails.bind(this,v)}>查看详情</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <Footer></Footer>
            </div>
        )
    }
}
export default News