import React from 'react'
import Header from '../header';
import { Component } from "react";
import banner from '../img/policy.jpg'
import Footer from '../footer';
import policy_back1 from '../img/policy_back1.jpg'
import policy_back2 from '../img/policy_back2.jpg'
import '../css/style.min.css'
class Details extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        console.log(this.state)
    }

    componentWillUnmount() {

    }
    render() {
        return (
            <div>
                <Header data={'relative'} />
                <div className="detalisCare">
                    <div className="title_box">
                       <div className="wauto">
                       <p className="sour">BTX动态  / 媒体相关报道</p>
                        <h2 className="t">银杏谷生态企业云徙科技完成3.5亿B轮融资</h2>
                        <p className="time">2020年4月21日</p>
                       </div>
                    </div>
                    <div className="text_box wauto">
                        <p>在智能化第四次工业革命背景下，各个领域数字化趋势日益凸显。据IDC数据显示，近几年我国数字经济占GDP比重逐年增加。2018年我国数字经济规模已经达到了31.3万亿元，占GDP的比重达到34.8%。据预测我国未来三年年均复合增长率将超过16%，到2022年将达到60.6万亿元规模。未来数字经济在整体规模、创新应用、产业覆盖面等各方面都有望创造新高，数字化经济未来将产生巨大的经济效益。市场规模的增长，必然会带来资本对数字化行业的投资增长，云徙作为国内领先的中台服务商，位列阿里云生态伙伴中国第一梯队，不俗的实力让云徙在资本市场中备受青睐。 </p>
                        <p>云徙的实力使其在资本市场备受欢迎。从团队实力来看，云徙现有员工600+，其中技术团队占公司人数85%以上，其核心成员来自顶尖企业服务厂商，在数字化行业有多年的经验，具有强大的业务与研发创新能力。此次发布会上宣布，前阿里巴巴(中国)有限公司副总裁兼数据委员会会长车品觉将加入云徙担任云徙董事、数据战略顾问，带领云徙向更高目标迈进；从技术实力来说，2016年云徙成立之初即开启业务中台研发。经过三年的发展，今年9月在云栖大会上发布了数字中台3.0，以“业务+数据”双中台为强劲内核，进一步释放“业务+数据”双中台的商业能力，更加关注企业如何拥有完整、全面的中台可运营能力，切实提高企业、业务部门、信息化部门、研发团队乃至一线技术人员的协同作战能力。</p>

                        <p>目前，云徙已成功帮助良品铺子、完美中国、珠江啤酒、富力地产、美的置业、长安汽车、爱驰汽车、威克多男装、联想惠商等众多头部企业成功实践数字化转型与商业创新，累计为企业创造4000亿以上GMV。在全球顶级机构的鼎力相持下，云徙将更进一步，全面帮助更多客户伙伴实现数据战略转型。 </p>

                        <p>数字中台在企业数字化转型中首当其冲</p>
                        <p>以房地产为例，房地产“跑马圈地”时代已经过去，现在，更注重精细化的管理及运营，通过运营和服务创新，挖掘利润增长点。房地产行业正经历打破单一的纯投资和开发的格局，拥抱运营和服务的存量阶段。 </p>
                        <p>据业内人士称，房地产企业后端存在很多消费场景和延伸服务，例如即售型房产、经营性物业、二手房，养老等。很多大型房企希望将这些后端资源整合性地提供给客户，实现经营性收入的多样性。但大多数的做法是让各个业务经营单元设计和推出自己的系统、APP、小程序，以各种方式推广给用户。这导致的结果是客户的感知和回馈是割裂的，对客户的需求把握更加是离散和难成体系的。“对品牌是一种极大的伤害。” </p>
                        <p>这就不难解释，做地产的会员积分体系和APP那么难。根本的在于组织结构没有合理设计而导致的组织资源，难以整合，难以协同经营。 </p>
                        <p>房企如何在产业互联网中破局，打造核心竞争力？建立以客户价值为中心的组织和经营机制呢？云徙科技副总裁李楠认为，要提升传统企业“平台+运营”的能力，阿里正好给全行业带来一种宝贵的实践思路，那就是中台战略。 </p>
                        <p>业内人士谈到，中台为企业带来的两大“好处”，一个为组织变革，突破原先集权式组织结构，转变成以实现客户价值为中心，柔性的组织结构；第二是IT能力落地，建设中台系统沉淀用户数据、业务能力，是IT与业务共同进行能力培育、以及将服务和运营能力释放的过程。 </p>
                        <p>云徙副总裁李楠表示，“目前从客户反馈来看，中台至少为企业节省了60％工作量。” </p>
                    </div>
                    <div className="btn">分享给您的好友</div>
                </div>
                <Footer></Footer>
            </div>
        )
    }
}
export default Details