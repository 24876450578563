import React from 'react'
import Header from '../header';
import { Component } from "react";
import CHAT from '../js/com'
import Footer from '../footer';
import page2Right from '../img/page2-right.jpg'
import page3Back from '../img/page3-back.png'
import page4Right from '../img/page4-right.png'
import iconAll from '../img/icon_all.png'
import '../css/style.min.css'
import Swiper from 'swiper'
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            swiper: ''
        }
    }
    componentDidMount() {
        if (!CHAT.IsPC()) {
            var swiper = new Swiper('.swiper-container', {
                direction: 'vertical',
                // autoplay: {   //滑动后继续播放（不写官方默认暂停）
                //     disableOnInteraction: false,
                // },
                watchSlidesProgress: true,
                mousewheel: true,
                // initialSlide: 4,
                on: {
                    progress: function (progress) {
                        console.log(progress);
                    },
                },
            })
            this.setState({ swiper })
        }
    }

    componentWillUnmount() {
        if (this.state.swiper) { // 销毁swiper
            this.state.swiper.destroy()
        }
    }
    render() {
        return (
            <div>
                <Header />
                <div className="swiper-container index-swiper">
                    <div className="swiper-wrapper">
                        {/* 第一页 */}
                        <div className="swiper-slide index-banner">
                            <div className="wauto">
                                <h2 className="title">中国领先的服务新经济的金融机构</h2>
                                <div className="hr"></div>
                                <h2 className="eng">CHINA RENAISSANCE</h2>
                                <p className="text">CHINA’S LEADING FINANCIAL INSTITUTION</p>
                                <p className="text">SERVING THE NEW ECONOMY</p></div>
                        </div>
                        {/* 第二页 */}
                        <div className="swiper-slide">
                            <p className="backtext">CAPITAL</p>
                            <div className="wauto index-page2">
                                <div className="left">
                                    <h2 className="all-titile">关于我们</h2>
                                    <p className="h3">BTX Capital是一家依托于产权市场为国内 <br />公司提供产权经纪、资产管理及咨询服务的专业机构</p>
                                    <p className="p">经过八年的发展耀之已经构建了投资研究、市场服务、后台运营、合规风控多位一体完整、行业先进的公司架构。是目前中国国内投研实力较强、管理规模较大的私募基金管理公司。</p>
                                    <br />
                                    <p className="p">秉承先进管理理念，参照国际顶尖资产管理机构的管理模式，结合中国市场实际情况建立完善的风险管理制度；并制定了完备的投资和交易流程，在最大程度上保证了投资操作的严谨、独立和专业。</p>
                                    <div className="btn">MORE <i></i></div>
                                </div>
                                <img className="right" src={page2Right} alt="" />
                            </div>
                        </div>
                        {/* 第三页 */}
                        <div className="swiper-slide">
                            <img src={page3Back} alt="" className="page3back" />
                            <div className="wauto index-page3">

                                <div className="box">
                                    <h2 className="all-titile">产品与服务</h2>
                                    <div className="item">
                                        <i className="i1"></i>
                                        <h2>产权交易经纪</h2>
                                        <p>建立了全生命周期投资管理平台。其股权投资板块贯穿天使、VC、PE、母基金。</p>
                                        <div className="more">MORE <i></i></div>
                                    </div>
                                    <div className="item">
                                        <i className="i2"></i>
                                        <h2>投资经营管理</h2>
                                        <p>对冲基金、家族办公室、保险公司及其他机构进行股票交易</p>
                                        <div className="more">MORE <i></i></div>
                                    </div>
                                    <div className="item">
                                        <i className="i3"></i>
                                        <h2>投资管理咨询</h2>
                                        <p>以中国为基地的新经济客户各种复杂的国内外并购交易提供咨询</p>
                                        <div className="more">MORE <i></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 第四页 */}
                        <div className="swiper-slide">
                            <div className="wauto index-page4">
                                <div className="left">
                                    <h2 className="all-titile">公司报道</h2>
                                    <p className="h3">创业成功的关键：天时、地利、人和</p>
                                    <p className="time">2019.08.08</p>
                                    <p className="p">跃迁式增长是闪击战：必须万事俱备，只欠东风，要找到那张浸满汽油的纸，并成为第一个或第一批点火的人。渐进式增长是持久战：核心是建立基于全市场的比较优势，要找到竞争对手最弱和自身优势最大的子市场...</p>
                                    <div className="line">
                                        <i></i>
                                        <i></i>
                                        <i></i>
                                        <i className="active"></i>
                                        <i></i>
                                    </div>
                                    <div className="btn">浏览全部新闻</div>
                                </div>
                                <img className="right" src={page4Right} alt="" />
                            </div>
                        </div>
                        {/* 第五页 */}
                        <div className="swiper-slide slide5">
                            <div className="wauto index-page5">
                                <div className="cot">
                                    <h2 className="all-titile">合作项目</h2>
                                    <img className="img_box" src={iconAll} alt="" />
                                </div>
                            </div>
                            <div className="page5line"></div>
                            <Footer></Footer>

                        </div>
                    </div>
                    <div className="swiper-pagination"></div>

                    <div className="swiper-scrollbar"></div>
                </div>
            </div>
        )
    }
}
export default Index