import React from 'react';
import { Component } from 'react';
import logo from './img/logo.png';
import CHAT from './js/com'
import './css/style.min.css'
import { Link } from 'react-router-dom'
// import Index from './html/index';
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isnav: false
    }
  }
  componentDidMount() {
    console.log(this.props)
    if (!CHAT.IsPC()) {
      this.setState({ isnav:true })
  }
  }
  navshow() {
    var { isnav } = this.state
    console.log(isnav)
    this.setState({ isnav: !isnav })
  }
  render() {
    var { isnav } = this.state
    return (
      <div className="App" style={{ position: this.props.data }}>
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />

          <div className="navlist iconfont icon-daohang" onClick={this.navshow.bind(this)}></div>
          {
            isnav?(<div className="header-list">
            <Link to="/">首页</Link>
            <Link to="/por">产品与服务 <i></i></Link>
            <Link to="/news">相关报道</Link>
            <Link to="/me">关于我们</Link>
            <Link to="/policy">市场策略</Link>
            <Link to="/about">联系我们</Link>
          </div>):''
            
          }
        </header>

      </div>
    )
  }
}
export default Header