import React from 'react'
import Header from '../header';
import { Component } from "react";
import banner from '../img/policy.jpg'
import Footer from '../footer';
import policy_back1 from '../img/policy_back1.jpg'
import policy_back2 from '../img/policy_back2.jpg'
import '../css/style.min.css'
class Policy extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
   
    }

    componentWillUnmount() {
     
    }
    render() {
        return (
            <div>
                <Header data={'relative'}/>
                <img className="policybanner probanner" src={banner} alt=""/>
                <div className="policyCare wauto">
                    <h2 className="pol2_title ">
                    BTX CAPITAL  / 市场策略
                    </h2>
                    <div className="pobox">
                        <div className="text">
                            <div className="t">投资主题与阶段</div>
                            <p className="mb">在建立互利互信的伙伴关系基础上通过积极主动的增值服务创造价值，促进已投企业创新与成长，帮助创建一流的公司。</p>
                            <p>－ 大联想资源与品牌影响力</p>
                            <p>－ 产业和企业管理经验丰富的合伙人</p>
                            <p>－ 专门设置的内部专家顾问团队</p>
                            <p>－ 全球多个资本市场上市的成功经验</p>
                        </div>
                        <img className="img" src={policy_back1} alt=""/>
                        <img className="img img2" src={policy_back2} alt=""/>
                        <div className="text">
                            <div className="t">区域发展与合作</div>
                            <p>专注于在重点领域内的创新与成长机会，在深入挖掘区域投资机会的同时也为被投企业的业务拓展提供帮助</p>
                            <p className="mb">重点投资于初创期和扩展期企业，兼顾种子期的项目，将联合部分有限合伙人共同对项目进行投资，为企业发展提供长期的资金支持</p>
                            <p>－ 资本在投资业界拥有广泛的人脉和合作基础，与同行共同投资，共创多赢。</p>
                        </div>
                    </div>
                </div>
              <Footer></Footer>
            </div>
        )
    }
}
export default Policy