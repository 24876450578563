import React from 'react';
import './App.css';
import Index from './html/index';
import Policy from './html/policy';
import Details from './html/details';
import About from './html/about';
import Por from './html/por';
import Me from './html/me';
import News from './html/news';
import {HashRouter as Router, Route,Switch  } from 'react-router-dom'

function App() {
  return (
    <Router>
      <Switch>
      <Route path="/" exact  component={Index}></Route>
      <Route path="/policy" component={Policy}></Route>
      <Route path="/por" component={Por}></Route>
      <Route path="/detalis" component={Details}></Route>
      <Route path="/about" component={About}></Route>
      <Route path="/me" component={Me}></Route>
      <Route path="/news" component={News}></Route>
      </Switch>
    </Router>
  );
}
export default App;
