import React from 'react'
import Header from '../header';
import { Component } from "react";
import banner from '../img/porbanner.jpg'
import Footer from '../footer';
import porImg2 from '../img/porImg2.png'
import '../css/style.min.css'
class Por extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {

    }

    componentWillUnmount() {

    }
    render() {
        return (
            <div>
                <Header data={'relative'} />
                <img className="probanner" src={banner} alt="" />
                <div className="porCare wauto">
                    <h2 className="pol2_title ">BTX CAPITAL  / 市场策略</h2>
                    <div className="wauto index-page3">
                        <div className="box">
                            <div className="item">
                                <i className="i1"></i>
                                <h2>产权交易经纪</h2>
                                <p>建立了全生命周期投资管理平台。其股权投资板块贯穿天使、VC、PE、母基金。</p>
                            </div>
                            <div className="item">
                                <i className="i2"></i>
                                <h2>投资经营管理</h2>
                                <p>对冲基金、家族办公室、保险公司及其他机构进行股票交易</p>
                            </div>
                            <div className="item">
                                <i className="i3"></i>
                                <h2>投资管理咨询</h2>
                                <p>以中国为基地的新经济客户各种复杂的国内外并购交易提供咨询</p>
                            </div>
                        </div>
                    </div>
                    <div className="box_bottom wauto">
                       <div className="leftImg" >
                       <img src={porImg2} alt="" />
                       </div>
                        <div className="right">
                            <h2 className="h">产权交易经纪</h2>
                            <p className="e">INVESTMENT</p>
                            <p className="e mb">MANAGEMENT</p>
                            <p className="t">私募融资: 我们的私募融资业务帮助成长期的公司募集资金，服务范围包括发起及执行股权、债务及相关证券的私募融资。在提供私募融资服务时，我们会就资本架构提出意见并协助客户制定长期资本规划。</p>
                            <p className="t">并购顾问: 我们向以中国为基地的新经济客户各种复杂的国内外并购交易提供咨询。我们的并购顾问服务帮助国内收购方接触优质的境外公司，连接国际机构投资者与境内优质新经济机遇。</p>
                            <p className="t">医疗顾问: 我们的专业医疗顾问团队具有深厚的行业和金融交易经验，研究范围覆盖逾十个细分行业包括细胞治疗、基因学及生物医药，总数逾300家公司。</p>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        )
    }
}
export default Por