import React from 'react'
import Header from '../header';
import { Component } from "react";
import banner from '../img/porbanner.jpg'
import Footer from '../footer';
import pic1 from '../img/pic1.jpg'
import pic2 from '../img/pic2.jpg'
import pic3 from '../img/pic3.jpg'
import firend1 from '../img/firend/1.png'
import firend2 from '../img/firend/2.png'
import firend3 from '../img/firend/3.png'
import firend4 from '../img/firend/4.png'
import firend5 from '../img/firend/5.png'
import firend6 from '../img/firend/6.png'
import '../css/style.min.css'
class Me extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firends: [firend1, firend2, firend3, firend4, firend5, firend6]
        }
    }
    componentDidMount() {

    }

    componentWillUnmount() {

    }
    render() {
        return (
            <div>
                <Header data={'relative'} />
                <img className="probanner" src={banner} alt="" />
                <div className="meCare wauto">
                    <div className="tab">
                        <div className="active">公司概述</div>
                        <div>团队成员</div>
                        <div>合作伙伴</div>
                    </div>
                    <div className="jj">
                        <div className="l">
                            <h2 className="h">公司概述</h2>
                            <p className="et">COMPANY</p>
                            <p className="et">OVERVIEW</p>
                        </div>
                        <div className="r">
                            <p>BTX CAPITAL专注于区块链领域的风险投资机构。已为 190 多个国家／地区的 40,000 多家机构服务，提供信息、洞察和技术，并推动全球金融市场的创新和业绩。我们的诚信传统使客户能够自信地做出关键决策，而我们行业一流的数据和尖端技术能够为客户带来更好的机遇。通过为客户创造先机，我们也推动着整个金融界的进步。</p>
                            <p>我们是中国领先的服务新经济的金融机构，公司业务包括私募融资、并购、直接投资、证券承销及发行、证券销售、交易及经纪、研究、 结构性产品、资产管理、财富管理及其他服务，致力于为中国新经济创业者、投资人提供横跨大陆、美国的一站式金融服务。</p>
                        </div>
                    </div>
                    <div className="cy">
                        <h2 className="h">团队成员</h2>
                        <div className="et">LEADERSHIP</div>
                        <div className="list">
                            <div className="item">
                                <img src={pic1} alt="" />
                                <div>
                                    <p className="t1">Peter Chen 陈哲</p>
                                    <p className="zw">副总裁</p>
                                    <p className="kkd">● 曾就职于Google，BlackBerry，担任高级工程师</p>
                                    <p className="kkd">● 专注智能制造、人工智能及黑科技领域</p>
                                </div>
                            </div>
                            <div className="item">
                                <img src={pic2} alt="" />
                                <div>
                                    <p className="t1">Peter Chen 陈哲</p>
                                    <p className="zw">副总裁</p>
                                    <p className="kkd">● 曾就职于Google，BlackBerry，担任高级工程师</p>
                                    <p className="kkd">● 专注智能制造、人工智能及黑科技领域</p>
                                </div>
                            </div>
                            <div className="item">
                                <img src={pic3} alt="" />
                                <div>
                                    <p className="t1">Peter Chen 陈哲</p>
                                    <p className="zw">副总裁</p>
                                    <p className="kkd">● 曾就职于Google，BlackBerry，担任高级工程师</p>
                                    <p className="kkd">● 专注智能制造、人工智能及黑科技领域</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="meCare_friend">
                    <div className="wauto">
                        <h2 className="h">团队成员</h2>
                        <div className="et">LEADERSHIP</div>
                        <div className="friend_img_box">
                            {this.state.firends.map((item, index) => (
                                <img src={item} key={index} alt="" />
                            )
                            )}
                        </div>
                    </div>
                    </div>
                <Footer></Footer>
            </div>
        )
    }
}
export default Me