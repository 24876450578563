import React from 'react'
import Header from '../header';
import { Component } from "react";
import banner from '../img/aboutbanner.jpg'
import aboutop from '../img/aboutop.png'
import mapImg from '../img/map.jpg'
import Footer from '../footer';
import '../css/style.min.css'
class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {

    }

    componentWillUnmount() {

    }
    render() {
        return (
            <div>
                <Header data={'relative'} />
                <img className="probanner" src={banner} alt="" />
                <div className="aboutCare ">
                    <div className="yuan_box">
                        <div className="wauto">
                            <div className="item">
                                <div className="">投资人关系</div>
                                <p>career@huadfgtfg.com</p>
                            </div>
                            <div className="item">
                                <div className="">投资人关系</div>
                                <p>career@huadfgtfg.com</p>
                            </div>
                            <div className="item">
                                <div className="">投资人关系</div>
                                <p>中国内地媒体 :</p>
                                <p>pr@huaxing.com</p>
                                <br />
                                <p>国际及中国香港媒体:</p>
                                <p>herahe@chinaren45nce.com</p>
                            </div>
                            <div className="item">
                                <div className="">投资人关系</div>
                                <p>早期项目 :</p>
                                <p>bpalpha@hua5g.com</p>
                                <br />
                                <p>中后期项目:</p>
                                <p>bpadvisory@huaxing.com</p>
                            </div>
                        </div>
                    </div>
                    <div className="mapBox">
                        <img className="img_abouto" src={aboutop} alt=""/>
                        <div className="map">
                            <img src={mapImg} alt=""/>
                            <p>联系地址</p>
                            <p>上海市徐汇区淮海中路1010号嘉华中心</p>
                        </div>
                    </div>
                </div>

                <Footer></Footer>
            </div>
        )
    }
}
export default About